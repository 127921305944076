console.log("loading api.js");

function logMessage(s) {
  console.log(s);
  //response.appendChild(document.createTextNode('\n' + s + '\n'));
}

function logMessageTime(s) {
  logMessage(new Date().toLocaleTimeString() + " " + s);
}
// Called by apiGet and apiGetMobile
async function apiGetGeneral(method, parameters, msalObject) {
  console.log("Executing apiGetGeneral...");

  let parameterString = "";

  if (parameters !== undefined) {
    parameterString = "?" + new URLSearchParams(parameters);
  }

  console.log("apiGetGeneral parameter string : " + parameterString);

  let uriEndpoint = msalObject.apiUri + method + parameterString;

  try {
    let response = await msalObject.getTokenPopup();
    console.log(response);
    if (response) {
      console.log(
        "coreMSALObj access_token acquired at: " + new Date().toString()
      );
      try {
        let rez = await callApi(uriEndpoint, response.accessToken);
        console.log("apiGetGeneral result from callApi =>");
        console.log(rez);
        return rez;
      } catch (error) {
        console.log(error);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

//Called by apiGetGeneral
async function callApi(endpoint, token) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  let noResultReturned = true;

  console.log("Executing callApi... endpoint: " + endpoint);

  headers.append("Authorization", bearer);

  logMessage("Bearer token:");
  logMessage(bearer);
  const options = {
    method: "GET",
    headers: headers,
  };

  logMessageTime("Calling API endpoint " + endpoint);
  return fetch(endpoint, options) // use return here
    .then((res) => {
      if (res.ok) {
        noResultReturned = false;
        return res.json();
      }
      noResultReturned = false;
      console.log("response not ok");
      logMessageTime("Error " + res.status + ". " + res.statusText);
      console.error(res);
      return Promise.reject();
    })
    .then((r2) => {
      logMessageTime("Web API responded: " + JSON.stringify(r2));
      console.log("Web API response raw =>");
      console.log(r2);
      return r2;
    })
    .catch((error) => {
      if (noResultReturned) {
        var networkError =
          "An network error occured making the request. The API service may not be running.";
        logMessageTime(networkError);
        console.error(networkError);
      } else {
        if (error !== undefined) logMessageTime(error);
        console.error(error);
      }
    });
}

async function callToken(msalObject, vuex) {
  try {
    let response = await msalObject.getTokenPopup();
    if (response) {
      let { accessToken, idTokenClaims } = response;
      let token = accessToken;

      
      vuex.commit("setUsername", idTokenClaims.name);
      vuex.commit("setEmail", idTokenClaims.emails[0]);
      vuex.commit("setOid", idTokenClaims.oid);
      vuex.commit("setRol", idTokenClaims.extension_Role);
      vuex.commit("setToken", token);
      vuex.commit("isActive", true);
      vuex.commit("setExp",idTokenClaims.exp)
      

      localStorage.setItem("token", token);
      localStorage.setItem("username", idTokenClaims.name);
      localStorage.setItem("email", idTokenClaims.emails[0]);
      localStorage.setItem("oid", idTokenClaims.oid);
      localStorage.setItem("extension_rol", idTokenClaims.extension_Role);
      localStorage.setItem("isActive", true);
      localStorage.setItem("exp", idTokenClaims.exp);

      console.log("token añadido");
    }
  } catch (error) {
    console.log(error);
  }
}

module.exports = {
  apiGetGeneral,
  callApi,
  callToken,
};
